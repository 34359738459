<template>
	<CommonPage :title="domainConfig['iamkeywords']">
		<div class="index">
			<Wrapper color="#121212">
				<div class="top">
					<h1>{{ domainConfig.title }}</h1>
					<Ad :ads="adsensConfig.ad4"></Ad>
					<div class="date">
						{{ `${month} | ${day}` }}
					</div>
				</div>
			</Wrapper>

			<component
				:is="contentComponent"
				:curOne="curOne"
				:sty="{ height: '45vh' }" />
			<Wrapper color="#ffffff">
				<div class="b">
					<Ad
						:ads="adsensConfig.ad5"
						:style="{ marginTop: isPC ? '50px' : 0, textAlign: 'center' }"></Ad>
					<div
						class="pa"
						v-html="curOne?.description"></div>
					<div class="son">
						<Ad :ads="adsensConfig.ad6"></Ad>

						<div class="text">Related Content</div>
						<div class="hs">
							<div
								v-for="item in list"
								:key="item?.title"
								@click="clk(item)"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.category }}</div>
								<div class="title">{{ item?.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
const _m = () => import('@/pages/components/bannerM.vue') //代码切割
const _pc = () => import('@/pages/components/bannerPC.vue') //代码切割
import '@/css/index.scss'
import { detail, getList } from '../api';
export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	data() {
		return {
			month: '',
			day: '',
			contentComponent: null,
			posi: '',
			quoteEng: '',
			chapters: [],
			curOne: null,
			list: [],
		}
	},
	computed: {
		description() {
			return this.domainConfig.description
		},
		title() {
			return `Detail | ${this.domainConfig['title']}`
		},
	},
	created() {
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
	async activated() {
		if (!this.$route.params.id) {
			this.$router.push('/')
			return
		}
		this.curOne = await detail({
			id: this.$route.params.id
		})
		let res = await getList({
			type: this.domainConfig['type'],
			pageNum: 1,
			pageSize: 30
		})
		this.list = res.popularizations
		console.log(this.list);
		console.log(this.$route.params.id)


		const today = new Date()
		// 定义月份名称数组
		const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

		// 获取月份和日期
		const month = monthNames[today.getMonth()]
		const day = today.getDate()
		this.month = month
		this.day = day

		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	async mounted() {
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	methods: {
		clk (item) {
			console.log(22);
			this.$router.push({name: 'detail', params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') }})
		},
	},
}
</script>